import Button from '@components/Button';
import Components from '@components/Components';
import ConfiguratorLayout from '@components/ConfiguratorLayout';
import CreateProject from '@components/CreateProject';
import Price from '@components/Price';
import PrintHeading from '@components/PrintHeading';
import ProductCode from '@components/ProductCode';
import ProductDisplay from '@components/ProductDisplay';
import ProductDisplayHeading from '@components/ProductDisplayHeading';
import Quantity from '@components/Quantity';
import SEO from '@components/seo';
import TearSheetCover from '@components/TearSheetCover';
import TearSheetDrawings from '@components/TearSheetDrawings';
import AuthUserContext from '@context/AuthUserContext';
import imageUrlFor from '@helpers/imageUrlFor';
import { viewItemDL } from '@src/helpers/dataLayerHelper';
import Link from '@utility/Link';
import PortableText from '@utility/PortableText';
import cn from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

export const query = graphql`
  query ($id: String) {
    sanityProductBasic(id: { eq: $id }) {
      certificationList
      _rawSummary(resolveReferences: { maxDepth: 10 })
      dimensions
      metaDescription
      dimensionalWeight
      downloads {
        drawings
        revit
        sketchUp
        ies
        techPack
        installInstructions
        other {
          title
          link
        }
        other2 {
          title
          link
        }
      }
      downloadLabel
      iesDrawings {
        label
        image {
          asset {
            _id
            extension
            url
          }
          altText
        }
      }
      iesDrawingsLabel
      iesRenders {
        label
        image {
          asset {
            _id
          }
          altText
        }
      }
      iesRendersLabel
      ledLifespan
      materials
      modification
      performance
      suspension
      title
      technicalDrawing {
        altText
        asset {
          url
          _id
        }
      }
      image {
        asset {
          _id
        }
      }
      technicalLabel
      minLeadTime
      maxLeadTime
      price
      discountPrice
      _rawComponents(resolveReferences: { maxDepth: 10 })
      _type
    }
  }
`;

const SkuBasicTemplate = ({
  data: { sanityProductBasic },
  pageContext: { slug, skuString },
}) => {
  const {
    minLeadTime,
    maxLeadTime,
    title,
    price,
    image,
    _rawSummary,
    discountPrice,
    _rawComponents,
    metaDescription,
  } = sanityProductBasic;

  const [quantity, setQuantity] = useState(1);
  const [productAdded, setProductAdded] = useState(false);
  // for project purposes
  const projectJson = `
  {
    "name": "${title}",
    "category": "Accessory",
    "description": ${JSON.stringify({
      0: { attr: 'Type', value: 'Accessory' },
    })},
    "image": "${imageUrlFor(image).height(1340).width(894).fit('crop').url()}",
    "sku": "${skuString}",
    "price": ${price / 100},
    "discount_price": ${discountPrice / 100}
  }
  `;
  // for seo purposes
  const projectJsonSEO = `
      {
        "@context": "https://www.schema.org",
        "@type": "product",
        "brand": {
          "@type": "Brand",
          "name": "RBW"
        },
        "name": "${title}",
        "category": "Accessory",
        "image": "${imageUrlFor(image)
          .height(1340)
          .width(894)
          .fit('crop')
          .url()}",
        "description": "${metaDescription}",
        "sku": "${skuString}",
        "logo": "https://rbw.com/images/rbw_logo.svg",
        "offers": {
          "@type": "Offer",
          "priceCurrency": "USD",
          "price": "${price && price / 100}",
          "url": "${`https://rbw.com/${slug.current}`}"
        },
        "url": "${`https://rbw.com/${slug.current}`}"
      }
      `;
  const { authUser } = useContext(AuthUserContext);

  useEffect(() => {
    viewItemDL({
      price,
      productCode: skuString,
      name: title,
      variant: 'Accessory',
      quantity: 1,
      skuSuffix: 'Accessory',
      trade_discount: !!authUser?.user_trade,
    });
  }, [])

  return (
    <div className="flex flex-col md-down:overflow-hidden">
      <SEO
        title={`Buy ${title} From RBW | Top Designer LED Lighting Manufacturer Company in New York`}
        description={metaDescription || null}
        image={image?.asset ? imageUrlFor(image).url() : null}
      >
        <script type="application/ld+json" id="js-project-card">
          {projectJson}
        </script>
        <script type="application/ld+json">{projectJsonSEO}</script>
      </SEO>
      <PrintHeading title={title} productType="Accessory" />
      <div className="wrapper print:order-2">
        <div className="row pointer-events-none md:z-20 md:-mt-5 print:hidden">
          <div className="col-12 md:col-7">
            <ProductDisplayHeading>
              <div className="type-sans-030 text-mono-500">
                <Link
                  to="/accessories"
                  underline={false}
                  aria-label="Accessories"
                  className="after:h-[75%]"
                >
                  Accessories
                </Link>
                {` / ${title}`}
              </div>
              <h1 className="type-sans-830 lg:type-sans-930 mb-2 pr-2 md:pr-6">
                {title}
              </h1>
            </ProductDisplayHeading>
          </div>
        </div>
        <div className="row">
          <div className="col-12 -mt-12 print:mt-0  ">
            <ConfiguratorLayout
              type="wide"
              imageChildren={
                // image url for gifs
                <img
                  src={imageUrlFor(image).maxHeight(2100).maxWidth(1400).url()}
                  alt=""
                  className="object-fit w-full"
                />
              }
            >
              <div className="md:sticky md:webkit-sticky md:top-0">
                {_rawSummary?.richText && (
                  <div className="richtext-content type-sans-430">
                    <PortableText blocks={_rawSummary.richText} />
                  </div>
                )}
                <ProductCode productCode={skuString}></ProductCode>
                <div className="xl:flex print:hidden mb-10">
                  <Quantity minLeadTime={minLeadTime} maxLeadTime={maxLeadTime}>
                    <input
                      type="number"
                      className="bg-mono-100 w-16 p-2 pl-6"
                      value={quantity}
                      min="1"
                      max="99"
                      onChange={(event) => {
                        setQuantity(parseInt(event.target.value, 10));
                      }}
                    />
                  </Quantity>
                  <Price price={price} discount_price={discountPrice} />
                </div>
                <ul className="print:hidden">
                  <li className="mb-2">
                    <CreateProject
                      sku={skuString}
                      quantity={quantity}
                      slug={`/${slug.current}`}
                    />
                  </li>
                  <li>
                    <Button
                      aria-label="Buy"
                      className={cn(
                        'hidden btn-default btn-black btn-lg btn-interaction btn-interaction--black w-full type-upper-240 snipcart-add-item',
                        {
                          'is-loading': productAdded,
                          btn:
                            authUser === null ||
                            authUser?.emailVerified === false ||
                            authUser?.user_trade === false,
                        },
                      )}
                      disabled={
                        authUser?.emailVerified === true &&
                        authUser?.user_trade === true
                      }
                      onClick={() => {
                        setProductAdded(true);
                        setTimeout(() => {
                          setProductAdded(false);
                        }, 1500);
                      }}
                      data-item-id={skuString}
                      data-item-description={skuString}
                      data-item-name={title}
                      data-item-categories={'Accessory'}
                      data-item-quantity={quantity}
                      data-item-price={price / 100}
                      data-item-url={
                        process.env.GATSBY_DEVELOPMENT_MODE === 'true'
                          ? `https://rbw-qa.netlify.app/${slug.current}`
                          : `/${slug.current}`
                      }
                      data-item-image={`${imageUrlFor(image)
                        .height(1340)
                        .width(894)
                        .fit('crop')
                        .url()}`}
                      data-item-stackable="always"
                      data-item-custom100-name="Actual Price"
                      data-item-custom100-value={price / 100}
                      data-item-custom100-type="hidden"
                      data-item-custom101-name="isUserTrade"
                      data-item-custom101-value={!!authUser?.user_trade}
                      data-item-custom101-type="hidden"
                      data-item-custom0-name="Type"
                      data-item-custom1-name="Accessory"
                    >
                      <span>Buy</span>
                      <span>Added to cart</span>
                    </Button>
                    <Button
                      aria-label="Buy"
                      className={cn(
                        'hidden btn-default btn-black btn-lg btn-interaction btn-interaction--black w-full type-upper-240 snipcart-add-item',
                        {
                          'is-loading': productAdded,
                          btn:
                            authUser?.emailVerified === true &&
                            authUser?.user_trade === true,
                        },
                      )}
                      disabled={
                        authUser === null ||
                        authUser?.emailVerified === false ||
                        authUser?.user_trade === false
                      }
                      onClick={() => {
                        setProductAdded(true);
                        setTimeout(() => {
                          setProductAdded(false);
                        }, 1500);
                      }}
                      data-item-id={`${skuString}-discount`}
                      data-item-description={skuString}
                      data-item-name={title}
                      data-item-categories={'Accessory'}
                      data-item-quantity={quantity}
                      data-item-price={discountPrice / 100}
                      data-item-url={
                        process.env.GATSBY_DEVELOPMENT_MODE === 'true'
                          ? `https://rbw-qa.netlify.app/${slug.current}`
                          : `/${slug.current}`
                      }
                      data-item-image={`${imageUrlFor(image)
                        .height(1340)
                        .width(894)
                        .fit('crop')
                        .url()}`}
                      data-item-stackable="always"
                      data-item-custom100-name="Actual Price"
                      data-item-custom100-value={price / 100}
                      data-item-custom100-type="hidden"
                      data-item-custom101-name="isUserTrade"
                      data-item-custom101-value={!!authUser?.user_trade}
                      data-item-custom101-type="hidden"
                      data-item-custom0-name="Type"
                      data-item-custom1-name="Accessory"
                    >
                      <span>Buy</span>
                      <span>Added to cart</span>
                    </Button>
                  </li>
                </ul>
              </div>
            </ConfiguratorLayout>
            <TearSheetDrawings
              technicalDrawing={sanityProductBasic.technicalDrawing}
            />
          </div>
        </div>
      </div>
      <div className="wrapper print:order-1">
        <TearSheetCover>
          <img
            src={imageUrlFor(image).maxHeight(2100).maxWidth(1400).url()}
            alt=""
            className="object-fit w-full"
          />
        </TearSheetCover>
        <div className="row avoid-break   border border-white">
          <div className="col-12">
            <ProductDisplay sanityProduct={sanityProductBasic} />
          </div>
        </div>
      </div>
      <div>
        {_rawComponents &&
          _rawComponents.map((element) => (
            <div className="py-12 md:py-20" key={element._key}>
              {React.createElement(Components(element._type), {
                key: element._key,
                data: { ...element },
              })}
            </div>
          ))}
      </div>
    </div>
  );
};

SkuBasicTemplate.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    skuString: PropTypes.string.isRequired,
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }),
  }).isRequired,
  data: PropTypes.shape({
    sanityProductBasic: PropTypes.shape({
      maxLeadTime: PropTypes.number.isRequired,
      minLeadTime: PropTypes.number.isRequired,
      technicalDrawing: PropTypes.array,
      title: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      discountPrice: PropTypes.number.isRequired,
      _rawSummary: PropTypes.object,
      metaDescription: PropTypes.string,
      image: PropTypes.object.isRequired,
      _rawComponents: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

SkuBasicTemplate.defaultProps = {
  data: {
    _rawSummary: {},
    _rawComponents: [],
    metaDescription: '',
  },
};

export default SkuBasicTemplate;
